<template>
    <div class="p-2 d-flex flex-fill flex-column">
        <h3 class="font-weight-bold text-white">Manage Booked Schedule</h3>

        <div class="">

            <h3 class="font-weight-bold text-white text-center mt-1">No Data Available</h3>

            <div v-for="item in schedules" class="p-1 mt-1"
                style="background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                <div class="d-flex justify-content-between align-items-center" style="gap:10px">
                    <h3 class="text-white mb-0">{{ item.user.user_name }}</h3>
                    <div class="text-white">
                        Booked on {{ item.created_at }}
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center" style="gap:10px;margin-top:.5em">
                    <div class="text-white">
                        Status :
                        <div class="badge badge-success" v-if="item.status == 'y'">Approved</div>
                        <div class="badge badge-danger" v-else-if="item.status == 'n'">Rejected</div>
                        <div class="badge badge-warning" v-else>Pending</div>
                    </div>
                    <div class="d-flex" style="gap:5px" v-if="!item.status">
                        <div class="btn btn-success btn-icon btn-sm" style="padding:.2em" @click="changeStatus('y', item.vsb_id)">
                            <i class="bx bx-check text-white" style="font-size: 18px;"></i>
                        </div>
                        <div class="btn btn-danger btn-icon btn-sm" style="padding:.2em" @click="changeStatus('n', item.vsb_id)">
                            <i class="bx bx-x text-white" style="font-size: 18px;"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import store from '@/store'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
    computed: {
        schedules() {
            return store.state.vet.SCHEDULE_LIST
        }
    },
    methods: {
        changeStatus(status, id) {
            let formData = new FormData()
            formData.append('status', status)
            formData.append('vsb_id', id)
            store.dispatch('vet/ChangeStatus', formData).then(() => {
                store.dispatch('vet/GetSchedule', this.vs_id)
            })

            // Swal.fire({
            //     html: `
            //         <h4 class="font-weight-bolder">Note to your client here</h4>
            //     `,
            //     input: 'text',
            //     inputAttributes: {
            //         autocapitalize: 'off'
            //     },
            //     showCancelButton: true,
            //     confirmButtonText: 'Submit',
            //     showLoaderOnConfirm: true,
            //     allowOutsideClick: () => !Swal.isLoading()
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         formData.append('note', result.value)
                    
            //     }
            // })
        }
    },
    mounted() {
        store.dispatch('vet/GetSchedule', this.vs_id)
    },
    data() {
        return {
            vs_id: this.$route.params.vs_id,
            moment
        }
    }
}

</script> 